import '../css/style.scss';
//import './script';

// Do not allow displaying in a frame
if (top != self) {
    alert('You will be redirected to the target page.');
    top.location=self.location;
}

// Ensure https
if (window.location.protocol != "https:" && window.location.hostname != 'localhost' && window.location.protocol != 'file:') {
    var restOfUrl = window.location.href.substr(5);
    window.location = "https:" + restOfUrl;
}

// Activate mail links
export function writeMail() {
    if (document.getElementById("maillink") != null) {
        document.getElementById("maillink").innerHTML='<a href="mailto:daniel.bolender' + '@alpen-biken.de">Daniel Bolender<\/a>';
    }
}

writeMail();

export function Rollover(out, over) {
    this.img1 = new Image();
    this.img1.src = out;
    this.img2 = new Image();
    this.img2.src = over;
}

export function rollover(bildname, rollover) {
    img = document.getElementById(bildname);
    img.src = rollover.img2.src;
}

export function rollout(bildname, rollover) {
    img = document.getElementById(bildname);
    img.src = rollover.img1.src;
}

export function openimage(link, width, height) {
    const _width = this.checkWidth(width + 80);
    const _height = this.checkHeight(height + 120);
    const options = 'resizable=yes,width='+_width+',height='+_height+',status=yes,scrollbars=yes';
    const w = open(link,'fubbes',options);
    w.focus();
    return false;
}

export function checkHeight(height) {
//    if (height > (screen.height - 120)) {
//        return screen.height - 120;
//    } else {
//    	return height;
//    }
	return height;
}

export function checkWidth(width) {
//    if (width > (screen.width)) {
//        return screen.width;
//    } else {
//    	return width;
//    }
	return width;
}

export function openmap(link, query) {
    const w = open(link + '?' + query,'alpenbikenmap');
    w.focus();
    return false;
}


export function initMapWithTrack(mapuri, center, scale) {
    return initMapWithTracks([mapuri], center, scale);
}

export function initMapWithTracks(mapuris, center, scale) {
    const map = initMap();
    map.setView(center, scale);
    console.log('Rendering ' + mapuris.length + ' tracks')
    mapuris.forEach((mapuri) => addTrackToMap(map, mapuri));
    return map;
}

const parser = new DOMParser();

function addTrackToMap(map, mapuri, center, scale) {
    // Create new kml overlay
    if (typeof(localStorage) !== "undefined" && localStorage.getItem('mapuri') === mapuri) {
        console.log('Found in localStorage: ' + mapuri);
        let kmltext = localStorage.getItem('mapkml');
        let kml = parser.parseFromString(kmltext, 'text/xml');
        let track = new L.KML(kml);
        map.addLayer(track);
   } else {
       // Load kml file
       console.log('Loading from server: ' + mapuri);
       readTrack(mapuri)
           .then(kmltext => {         
                   if (typeof(localStorage) !== "undefined") {
                       localStorage.setItem('mapkml', kmltext);
                       localStorage.setItem('mapuri', mapuri);
                   }
                   let kml = parser.parseFromString(kmltext, 'text/xml');
                   let track = new L.KML(kml);
                   map.addLayer(track);
           }).catch(error => console.log(error));
   }
   return map;
}

let control;

export function rememberMapType(map) {
    if (typeof(localStorage) !== "undefined" && control != undefined) {
        localStorage.setItem('maptype', control.getActiveBaseLayer().name)
    }
}

// --------------------

function initMap() {
    const OpenStreetMap_Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    const OpenTopoMap = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
    });
    const Thunderforest_OpenCycleMap = L.tileLayer('https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=ae2b74e8499247ba9cffb4e9cc99a226', {
        attribution: '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        apikey: '<your apikey>',
        maxZoom: 22
    });
    const Thunderforest_Landscape = L.tileLayer('https://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=ae2b74e8499247ba9cffb4e9cc99a226', {
        attribution: '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        apikey: '<your apikey>',
        maxZoom: 22
    });
    const CyclOSM = L.tileLayer('https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
        maxZoom: 20,
        attribution: '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    const Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });
    const MtbMap = L.tileLayer('http://tile.mtbmap.cz/mtbmap_tiles/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &amp; USGS'
    });
    const FourU = L.tileLayer('https://tileserver.4umaps.com/{z}/{x}/{y}.png');
    
    const baseLayers = {
        "OpenTopoMap": OpenTopoMap,
        "OpenStreetMap": OpenStreetMap_Mapnik,
        "OpenCycleMap": Thunderforest_OpenCycleMap,
        "MtbMap": MtbMap,
        "CycloOSM": CyclOSM,
        "Landscape": Thunderforest_Landscape,
        "4UMaps": FourU,
        "Esri (Satellit)": Esri_WorldImagery
    };
    
    const defaultMap = OpenTopoMap;
    
    const mapType = baseLayers[restoreMapType()];
    const map = new L.Map('map');
    map.addLayer(mapType ? mapType : defaultMap); // otm is default

    control = L.control.activeLayers(baseLayers)
    control.addTo(map)
    return map;
}

function readTrack(mapuri) {
    return new Promise((resolve, reject) => {
        // Load kml file
        fetch(mapuri) // load uri from server
            .then(res => {
                if (mapuri.indexOf(".kmz") >= 0) { // if URL is a zip, wew must decompress
                    res.blob()
                        .then(blob => 
                            // use a BlobReader to read the zip from a Blob object
                            zip.createReader(new zip.BlobReader(blob), function(reader) {

                                // get all entries from the zip
                                reader.getEntries(function(entries) {
                                    entries.forEach(entry => {
                                        if (entry.getName = 'doc.kml') {
                                            // get first entry content as text
                                            entry.getData(new zip.TextWriter(), function(kmltext) {
                                                // close the zip reader
                                                reader.close(function() {
                                                    // onclose callback
                                                });
                                                resolve(kmltext);
                                            }, function(current, total) {
                                                // onprogress callback
                                            });
                                        }
                                    });
                                });
                            }, function(error) {
                                console.log(error);
                            }
                        )
                    );
                } else { // kml
                    res.text().then(text => resolve(text)); 
                }
            });
        });
}

function restoreMapType() {
    if (typeof(localStorage) !== "undefined") {
        return localStorage.getItem('maptype');
    } 
    return undefined;    
}    